import React, {useLayoutEffect, useState} from "react"
import { Parallax, ParallaxLayer } from '../components/Parallax';
import Layout from "../components/Layout";
import TitleCard from "../components/TitleCard";
import StandardScrollSection from "../components/StandardScrollSection";
import ReviewsStars from "../components/ReviewsStars";
import Ending from "../components/Ending";
import EndingMenuItem from "../components/EndingMenuItem";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { SEO } from "../components/SEO"

import {chaseGoldSlide, dropInDrop, showOutAppear, chopItUpType, victorsAppear, slowSlide, breathIn, toeSteps, victorsAppearDown} from "../helpers/animations";

const WanderersPage = () => {

  const [color, setColor] = useState("blue");
  const [itineraryLinkVisible, setitineraryLinkVisible] = useState(true);

  const nordicLink = "https://visitsunvalley.com/things-to-do/nordic-skiing/";
  const flyfishingLink = "https://visitsunvalley.com/things-to-do/fly-fishing/";
  const gardenLink = "https://visitsunvalley.com/summer/this-is-the-sawtooth-botanical-garden/";
  const yurtLink = "https://www.galenalodge.com/yurt-rentals.html";
  const golenaLink = "https://visitsunvalley.com/winter/experience-the-magic-of-galena-lodge/";

  const title = <>Welcome to<br/>Sun Valley, ID</>
  const formId = "5B5E7037DA78A748374AD499497E309E502F4BF4973C93788F2B72E4D66CB0D80855B4AB1B9A3BB7DCC341BBD938568A3B9FBD923923AC59196FAC707086EDD0";
  const emailName = "cm-bdtakt-bdtakt"
  const itineraryLink = "/VSV_Winter_Itinerary_Wanderers.pdf";
  const subTitle = "Scroll sideways to plan your nordic ski getaway today.";
  const altTitle = <>Wander<br/>Westword</>;

  return (
      <Layout color={color} itineraryLinkVisible={itineraryLinkVisible} itineraryLink={itineraryLink} formId={formId} emailName={emailName}>
        <Parallax pages={17.7} style={{ top: '0', left: '0' }}>
          <ParallaxLayer offset={0} speed={1}>
            <TitleCard title={title} altTitle={altTitle} instructions={subTitle} instructionsColor="blue" imgSrc="/VSV_LandingPage_Wanderers_01.jpg" />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={1}>
            <div className="ending-menu toc">
              <EndingMenuItem text="Nordic Ski" scrollTo="nordic-ski" duration=".5"/>
              <EndingMenuItem text="Flyfish" scrollTo="fly-fish" duration="1"/>
              <EndingMenuItem text="Meditate" scrollTo="meditate" duration="1.5"/>
              <EndingMenuItem text="Lodge" scrollTo="lodge" duration="2"/>
              <EndingMenuItem text="Feast" scrollTo="feast" duration="2.5"/>
            </div>
          </ParallaxLayer>

          <StandardScrollSection
            id="nordic-ski"
            offset={1.5}
            setNavColor={setColor}
            bgColor="yellow"
            titleColor="red"
            title={<>GET<br/>OUTSIDE</>}
            content={<>Hailed as <OutboundLink href={nordicLink} target="_blank">Nordic Town USA,</OutboundLink> Sun Valley offers some of the most immaculately groomed Nordic ski terrain in the states. Plus you can Nordic ski right outside your front door, across 200 km of lovely, networked trails.</>}
            intoImg='/VSV_LandingPage_Wanderers_08.jpg'
            backgroundSvg='/wanderers_bg_01.svg'
            foregroundImg='/VSV_LandingPage_Wanderers_09.png'
            trailImg='/VSV_LandingPage_Wanderers_10.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.to(imageRef.current, {x:"-40%", y:"-20%", ease: "none"})
            }}
            titleAnimationFunc={slowSlide}
            />

          <StandardScrollSection
            id="fly-fish"
            offset={4.5}
            setNavColor={setColor}
            bgColor="brown"
            titleColor="white"
            title={<>BREATHE<br/>IT IN</>}
            content={<><OutboundLink href={flyfishingLink} target="_blank">Fly fishing in Sun Valley</OutboundLink> is about as good as it gets. The level of access is unrivaled. And there are 300 days of delicious desert sunshine. Which, in the winter, means warmer toes and happier hearts.</>}
            intoImg='/VSV_LandingPage_Wanderers_11.jpg'
            backgroundSvg='/wanderers_bg_02.svg'
            foregroundImg='/VSV_LandingPage_Wanderers_12.png'
            trailImg='/VSV_LandingPage_Wanderers_13.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"0%", y:"-10%", scale: .8}, {x:"-20%", ease: "back.out(6)"})
            }}
            titleAnimationFunc={breathIn}
            />

          <StandardScrollSection
            id="meditate"
            offset={7.5}
            setNavColor={setColor}
            bgColor="red"
            titleColor="blue"
            title={<><span className="word">FEEL</span><span className="word">&nbsp;THE</span><br/><span className="word">EARTH</span></>}
            content={<>Visit the <OutboundLink href={gardenLink} target="_blank">Garden of Infinite Compassion,</OutboundLink> created 17 years ago to honor the Dalai Lama’s visit to the Wood River Valley. Meditate. Contemplate. And find inspiration in the beautiful prayer wheel blessed by the Dalai Lama himself.</>}
            intoImg='/VSV_LandingPage_Wanderers_14.jpg'
            backgroundSvg='/wanderers_bg_03.svg'
            foregroundImg='/VSV_LandingPage_Wanderers_15.png'
            trailImg='/VSV_LandingPage_Wanderers_16.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"-24%", scale: .5, y: "50%", ease: "none"}, { scale: 1, y:"0"})
            }}
            titleAnimationFunc={toeSteps}
            />

            <StandardScrollSection
              id="lodge"
              offset={10.5}
              setNavColor={setColor}
              bgColor="blue"
              titleColor="yellow"
              title={<>SLEEP UNDER<br/>THE STARS</>}
              content={<><OutboundLink href={yurtLink} target="_blank">Spending a night in a yurt</OutboundLink> in the middle of the mountains is straight magical. Get toasty by the hearth. Take a dip in the wood-fired sauna. Enjoy life in the wild. There’s nothing like it.</>}
              intoImg='/VSV_LandingPage_Wanderers_02.jpg'
              backgroundSvg='/wanderers_bg_04.svg'
              foregroundImg='/VSV_LandingPage_Wanderers_03.png'
              trailImg='/VSV_LandingPage_Wanderers_04.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .65, y: "20%", x:"-20%", ease: "none"}, { scale: 1.2, y: "-10%", ease: "none"})
              }}
              titleAnimationFunc={victorsAppearDown}
              />

            <StandardScrollSection
              id="feast"
              offset={13.5}
              setNavColor={setColor}
              bgColor="red"
              titleColor="brown"
              title={<>DINE UNDER<br/>THE MOON</>}
              content={<>Nearby <OutboundLink href={golenaLink} target="_blank">Galena Lodge</OutboundLink> opens for dinner a few nights each winter. Treat yourself to the Full Moon Meal, a communal, four-course dinner. Then rest by the fire before setting out on skis under lush moonlight.</>}
              intoImg='/VSV_LandingPage_Wanderers_05.jpg'
              backgroundSvg='/wanderers_bg_05.svg'
              foregroundImg='/VSV_LandingPage_Wanderers_06.png'
              trailImg='/VSV_LandingPage_Wanderers_07.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .65, y: "20%", x:"-24%", ease: "none"}, { scale: 1.2, y: "-10%", ease: "none"})
              }}
              titleAnimationFunc={victorsAppear}
              />

            <ReviewsStars offset={16.5} rating={4.8} />

            <Ending offset={17} title={title} onEnter={()=>setitineraryLinkVisible(false)} onExit={()=>setitineraryLinkVisible(true)}>
              <div className="ending-menu">
                <EndingMenuItem text="GET OUTSIDE" href={nordicLink} subtext="Learn more about Nordic skiing" />
                <EndingMenuItem text="BREATH IT IN" href={flyfishingLink} subtext="Learn more about fly fishing in the Valley" />
                <EndingMenuItem text="FEEL THE EARTH " href={gardenLink} subtext="Learn more about the Garden of Infinite Compassion" />
                <EndingMenuItem text="SLEEP UNDER THE STARS" href={yurtLink} subtext="Learn more about yurts" />
                <EndingMenuItem text="DINE UNDER THE MOON" href={golenaLink} subtext="Learn more about Galena Lodge" />
              </div>
            </Ending>

        </Parallax>
      </Layout>
    );
}

export default WanderersPage

export const Head = () => (
  <SEO title="Wanderers, Sun Valley Wants You" image="SEO_VSV_LandingPage_Wanderers_01.jpg" description="Are you a nature lover in search of some fresh air? Sun Valley, ID wants you."/>
)
